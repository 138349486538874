<template>
  <div class="background">
    <div class="setting-btn">
      <el-button icon="el-icon-s-tools" type="primary" circle @click="openDrawer" />
    </div>

    <div class="container">
      <el-tabs tab-position="left">
        <!-- 惩罚 -->
        <el-tab-pane>
          <div slot="label">
            <div class="label-first-layer">
              <div class="label-second-layer">
                <div class="label-third-layer">
                  <div class="label-text">惩罚</div>
                </div>
              </div>
            </div>
          </div>

          <!-- 显示全部 -->
          <div v-if="punish.selectItem === null">
            <div v-for="(item, index) in punish.arrays" :key="`punish-item-${index}`" class="container-title">
              <div class="title-first-layer" @click="selectPunish(item)">
                <div class="title-second-layer">
                  <div class="title-third-layer">
                    <div class="title-text" :style="item.times >= punishMaxSelect ? 'color: #999999;' : ''">{{ item.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 显示详细 -->
          <div v-else class="tab-pane-content-box">
            <div class="container-title">
              <div class="title-first-layer" @click="cancelPunish()">
                <div class="title-second-layer">
                  <div class="title-third-layer">
                    <div class="title-text">{{ punish.selectItem.title }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div style="width: 100%;">
              <!-- 玩法子项 -->
              <div v-if="punish.selectItem.subItems" style="text-align: center;">
                <el-button 
                  type="primary"
                  circle
                  @click="punish.selectItem.selectSubIndex = -1"
                >
                  <div>规则</div>
                </el-button>

                <el-button 
                  v-for="(subItem, subItemIndex) in punish.selectItem.subItems"
                  :key="`sub-item-${subItemIndex}`"
                  :type="punish.selectItem.selectSubIndex === subItemIndex ? 'info' : 'primary'"
                  :disabled="punish.selectItem.selectSubIndex === subItemIndex"
                  circle
                  @click="punish.selectItem.selectSubIndex = subItemIndex"
                >
                  <div>{{ zeroPad(subItemIndex + 1, 2) }}</div>
                </el-button>
              </div>
              <div v-if="punish.selectItem.subItems && punish.selectItem.selectSubIndex >= 0" class="punish-describe" style="margin-top: 10px;">
                {{ punish.selectItem.subItems[punish.selectItem.selectSubIndex] }}
              </div>

              <div v-else-if="punish.selectItem.subItems && punish.selectItem.selectSubIndex < 0" class="punish-describe" style="margin-top: 10px;">
                {{ punish.selectItem.describe }}
              </div>

              <div v-else class="punish-describe">{{ punish.selectItem.describe }}</div>
            </div>
          </div>
        </el-tab-pane>

        <!-- 倒计时 -->
        <el-tab-pane>
          <div slot="label">
            <div class="label-first-layer">
              <div class="label-second-layer">
                <div class="label-third-layer">
                  <div class="label-text">倒计时</div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane-content-box">
            <div class="container-title">
              <div class="title-first-layer">
                <div class="title-second-layer">
                  <div class="title-third-layer">
                    <div class="title-text">倒计时</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="countdown-text">{{ countdown.secondVal }}</div>

            <div class="countdown-col">
              <el-button @click="startInterval">开始</el-button>
              <el-button @click="endInterval">暂停</el-button>
              <el-button @click="resetInterval">重置</el-button>
            </div>

            <div class="countdown-col">
              <el-checkbox v-model="countdown.loop" :disabled="countdown.interval !== null">循环</el-checkbox>
              <el-checkbox v-model="countdown.boom" :disabled="countdown.interval !== null">爆炸</el-checkbox>
              <el-checkbox v-model="countdown.countSecond" :disabled="countdown.interval !== null">读秒</el-checkbox>
              <el-input-number v-model="countdown.maxSecondVal" :min="2" label="最大秒数" :disabled="countdown.interval !== null" />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <el-drawer
        :with-header="false"
        :visible.sync="tablePersonVisible"
        direction="rtl"
        size="50%"
        :before-close="closeDrawer"
      >
        <table-person-detail />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import tablePersonDetail from '@/components/tablePersonDetail'
import { zeroPad } from '@/utils/index'
import boomMp3 from '@/assets/audio/other/boom-1.mp3'
import secondHandMp3 from '@/assets/audio/other/secondHand.mp3'
// import boomMp3 from '@/assets/audio/other/boom-2.mp3'

export default {
  name: "Tool",

  components: { tablePersonDetail },

  data() {
    return {
      // 惩罚
      punish: {
        selectItem: null,
        arrays: [
          {
            title: '天真无邪',
            describe: '每句至少一个叠字，用可爱的语气读完这段话。',
            times: 0,
            selectSubIndex: -1,
            subItems: [
              "大家好！我是你们的萌主“喵喵”，一个热爱生活、永远十八岁的可爱少女哦！ \n 我性格活泼开朗，每天都充满了正能量，嘻嘻！我喜欢和朋友们一起玩耍，分享快乐，当然，还有卖萌啦！(o°ω°o) \n 在爱好方面，我喜欢看动漫、追剧，还有尝试各种美食！我觉得人生就应该尽情享受嘛！至于食物嘛，我最爱甜品了，尤其是巧克力蛋糕，简直让人欲罢不能呢！（＾-＾） \n 说到颜色，我喜欢粉红色哦！粉粉嫩嫩的，超级可爱！(o'ω'o) \n 我有一个愿望，就是希望能环游世界，看看那些美丽的风景，体验不同的文化。当然啦，如果能和喜欢的人一起，那就更完美啦！（＾-＾） \n 最后，我想说，让我们一起开心地度过每一天吧！(o°ω°o)",
              "大家好！我是一个超级开朗的大男孩，整天都嘻嘻哈哈的，爱说爱笑，还特别粘人哦~( *︾▽︾) \n 虽然我长相普通，但我特别会打扮自己，经常被人夸很可爱呢！(害羞ing) \n 平时我喜欢宅在家里看动漫、打游戏，还特别喜欢和猫咪玩耍！感觉有它们的陪伴，生活都变得温暖起来了呢~(*≧ω≦) \n 我的人生格言是：“做人呢，最重要的就是开心啦！”( corso)所以呀，我希望每一天都能过得开开心心、快快乐乐的！如果身边能有更多的小伙伴一起分享快乐，那就更完美啦！o(￣▽￣)ｄ \n 最后，希望大家都能和我一样，永远保持一颗童心，追寻自己的梦想和快乐！(0^◇^0)/",
            ],
          },
          {
            title: '多才多艺',
            describe: '表演才艺或绝活，需要得到观众的认可。',
            times: 0,
          },
          {
            title: '伶牙俐齿',
            describe: '准确迅速的读完绕口令。',
            times: 0,
            selectSubIndex: -1,
            subItems: [
              '六十六岁的陆老头，盖了六十六间楼，买了六十六篓油，养了六十六头牛，栽了六十六棵垂杨柳。 六十六篓油，堆在六十六间楼；六十六头牛，扣在六十六棵垂杨柳。 忽然一阵狂风起，吹倒了六十六间楼，翻倒了六十六篓油，折断了六十六棵垂杨柳，砸死了六十六头牛，急煞了六十六岁的陆老头。',
              '哥跨瓜筐过宽沟，快过宽沟观怪狗，光顾观狗瓜筐扣，瓜滚筐空哥怪狗。',
              '断头台倒吊短单刀，歹徒登台偷短刀，断头台塌盗跌倒，对对短刀叮当掉。',
              '打南边来个瘸子，担了一挑子茄子，手里拿着个碟子，地下钉着木头橛子。没留神那橛子绊倒了瘸子，弄撒了瘸子茄子，砸了瘸子碟子，瘸子毛腰拾茄子。北边来个醉老爷子，腰里掖着烟袋别子，过来要买瘸子茄子，瘸子不卖给醉老爷子茄子，老爷子一生气抢了瘸子茄子，瘸子毛腰捡茄子拾碟子，拔橛子，追老爷子，老爷子一生气，不给瘸子茄子，拿起烟袋别子，也不知老爷子的烟袋别子打了瘸子茄子，也不知瘸子橛子打了老爷子烟袋别子。',
              '七巷一个漆匠，西巷一个锡匠。七巷漆匠用了西巷锡匠的锡，西巷锡匠拿了七巷漆匠的漆，七巷漆匠气西巷锡匠用了漆，西巷锡匠讥七巷漆匠拿了锡。',
              '三山撑四水，四水绕三山，三山四水春常在，四水三山四时春。',
              '这天天下雨，体育局穿绿雨衣的女小吕，去找穿绿运动衣的女老李。穿绿雨衣的女小吕，没找到穿绿运动衣的女老李，穿绿运动衣的女老李，也没见着穿绿雨衣的女小吕。',
              '威威、伟伟和卫卫，拿着水杯去接水。威威让伟伟，伟伟让卫卫，卫卫让威威，没人先接水。一二三，排好队，一个一个来接水。',
              '王庄卖筐，匡庄卖网，王庄卖筐不卖网，匡庄卖网不卖筐，你要买筐别去匡庄去王庄，你要买网别去王庄去匡庄。',
              '圆圈圆，圈圆圈，圆圆娟娟画圆圈。娟娟画的圈连圈，圆圆画的圈套圈。娟娟圆圆比圆圈，看看谁的圆圈圆。',
              '西关村种冬瓜，东关村种西瓜，西关村夸东关村的西瓜大，东关村夸西关村的大冬瓜，西关村教东关村的人种冬瓜，东关村教西关村的人种西瓜。冬瓜大，西瓜大，两个村的瓜作个个大。',
              '蓝教练是女教练， 吕教练是男教练， 蓝教练不是男教练， 吕教练不是女教练。蓝南是男篮主力， 吕楠是女篮主力，吕教练在男篮训练蓝南， 蓝教练在女篮训练吕楠。',
              '白石白又滑，搬来白石搭白塔。白石塔，白石搭，白石搭石塔，白塔白石搭。搭好白石塔，白塔白又滑。',
              '老方扛着黄幌子，老黄扛着方幌子。老方要拿老黄的方幌子，老黄要拿老方的黄幌子，末了儿方幌子碰破了黄幌子，黄幌子碰破了方幌子。',
              '有个懒汉本姓阮，提个篮子卖鸡卵，“卖卵”“卖卵”使劲喊，谁都不来买鸡卵，不是鸡卵品种乱，而是鸡卵皮太软。卖不出鸡卵难买饭，回家吃饭路又远，胡乱烧把烂草取取暖，再吃掉篮子里几个软鸡卵。',
            ],
          },
          {
            title: '宽宏大量',
            describe: '说一段祝酒辞并喝酒。',
            times: 0,
          },
          {
            title: '孤芳自赏',
            describe: '自信且大声说出自己的6种优点，优点不可相近或重复。如：我是一个优秀、XX、XX...的人，我真棒。',
            times: 0,
          },
          {
            title: '冷若冰霜',
            describe: '无表情吃完提供的柠檬。',
            times: 0,
          },
          {
            title: '油嘴滑舌',
            describe: '与另一人配合着且深情的说完土味情话。',
            times: 0,
            selectSubIndex: -1,
            subItems: [
              'A：红豆可以做成红豆泥，土豆可以做成土豆泥。那你知道你可以做成什么泥吗？ \n B：什么？ \n A：我爱你。',
              'A：你猜我最爱上谁的课？ \n B：不知道 \n A：爱上你的每一刻',
              'A：你是什么血型？ \n B：A型 \n A：不，你是我的理想型',
              'A：你知道我喜欢什么衣服吗？ \n B：不知道 \n A：我喜欢被你制服',
              'A：帮我洗一样东西好不好？ \n B：洗什么？ \n A：喜欢我',
              'A：我们玩123某头人吧,对不起我输了， \n B：为什么？ \n A：因为我心动了',
              'A：猜猜我的心在哪边? \n B：左边。 \n A：错了，在你那边',
              'A：你什么时候交房租啊 \n B：为什么要交房租 \n A：因为你在我心里住了很久了啊”',
              'A：你知道我本来姓什么吗? \n B：姓什么？ \n A：我本来姓王，但是遇见了你，我就幸（姓）福了',
              'A：你会弹吉他吗？ \n B：不会啊。 \n A：那你怎么拨动了我的心弦',
              'A：你知道我的缺点是什么吗？ \n B：是什么？ \n A：缺点你',
              'A：你有打火机吗？ \n B：没有啊。 \n A：那你是怎么点燃我的心的',
              'A：你问到什么味道了吗？ \n B：没有啊。 \n A：那怎么你一出来空气都是甜的了',
              'A：你知道你和星星的区别吗？ \n B：什么区别？ \n A：星星点亮了黑夜，而你点亮了我的心',
              'A：你知道我的心在哪边吗？ \n B：左边啊。 \n A：不，在你那边',
              'A：我想吃碗面。 \n B：什么面？ \n A：你的心里面',
              'A：你猜我想吃什么？ \n B：不知道啊。 \n A：我想痴痴的望着你',
              'A：我觉得你好像一款游戏。 \n B：什么游戏？ \n A：我的世界',
            ],
          }
        ]
      },
      // 倒计时
      countdown: {
        secondVal: 10,
        maxSecondVal: 10,
        interval: null,
        loop: false,
        boom: false,
        countSecond: true,
      },
      // 单个惩罚最大选择次数
      punishMaxSelect: 10,
      // 人员显示判断
      tablePersonVisible: false,
      // 读秒声
      secondHandMusic: new Audio(secondHandMp3),
    }
  },

  methods: {
    zeroPad,

    // 开始倒计时
    startInterval() {
      const that = this
      // 开启计时器
      that.countdown.interval = setInterval(() => {
        if (that.countdown.secondVal === 0 && that.countdown.loop) {
          // 循环
          that.countdown.secondVal = that.countdown.maxSecondVal - 1
        } else if (that.countdown.secondVal === 0) {
          // 不循环
          clearInterval(that.countdown.interval)
          that.countdown.interval = null
        } else {
          // 减少秒数
          that.countdown.secondVal--

          if (that.countdown.countSecond) {
            // 播放读秒音效
            that.secondHandMusic.play()
          }

          if (that.countdown.boom && that.countdown.secondVal === 0) {
            // 播放爆炸音效
            const boomMusic = new Audio(boomMp3)
            boomMusic.play()
          }
        }
      }, 1000)
    },

    // 暂停倒计时
    endInterval() {
      const that = this
      if (that.countdown.interval !== null) {
        clearInterval(that.countdown.interval)
        that.countdown.interval = null
      }
    },

    // 重置倒计时
    resetInterval() {
      const that = this
      that.countdown.secondVal = that.countdown.maxSecondVal
    },

    // 选择惩罚
    selectPunish(item) {
      const that = this
      if (item.times < that.punishMaxSelect) {
        item.times++
        that.punish.selectItem = item
      }
    },

    // 取消选择惩罚
    cancelPunish() {
      const that = this
      that.punish.selectItem = null
    },

    // 开启弹出框
    openDrawer() {
      const that = this
      that.tablePersonVisible = true
    },

    // 关闭弹出框
    closeDrawer() {
      const that = this
      that.tablePersonVisible = false
    },
  }
}
</script>

<style scoped>
.background {
  min-height: 100vh;
  width: 100%;
  background-image: url('../../assets/images/background_2024.jpg');
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  background-position: center top;
  margin: 0px;
  padding: 0px 0px 20px 0px;
  pointer-events: auto;
  user-select: none;
}

.setting-btn {
  float: right;
  width: 40px;
  height: 40px;
}

.container {
  width: calc(100% - 120px);
  padding: 200px 0px 20px 0px;
}

.container-title {
  padding: 0px 0px 20px 0px;
}

.title-first-layer {
  margin: 0px auto;
  border: 6px solid #FFD86D;
  padding: 6px;
}

.title-second-layer {
  border: 2px solid #FFD86D;
  padding: 8px;
}

.title-third-layer {
  background-color: #D92411;
}

.title-text {
  font-family: cursive;
  font-size: 36px;
  font-weight: 600;
  color: #FFD86D;
  letter-spacing: 6px;
  text-align: center;
  text-shadow: 2px 2px #000000;
  padding: 0px 12px;
}

.label-first-layer {
  margin: 0px auto;
  border: 4px solid #FFD86D;
  padding: 3px;
}

.label-second-layer {
  border: 1px solid #FFD86D;
  padding: 2px;
}

.label-third-layer {
  background-color: #D92411;
  line-height: 20px;
}

.label-text {
  width: 85px;
  font-size: 14px;
  font-weight: 400;
  color: #FFD86D;
  text-align: center;
}

.tab-pane-content-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.punish-describe {
  width: 50vw;
  min-width: 100px;
  min-height: 200px;
  border: 3px solid #FFD86D;
  border-radius: 20px;
  padding: 20px;
  margin: 0px auto;
  font-family: cursive;
  font-size: 30px;
  color: #FFD86D;
  letter-spacing: 2px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.countdown-text {
  width: 100%;
  font-size: 240px;
  color: #FFD86D;
  text-align: center;
}

.countdown-col {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
  gap: 8px;
}

/* 定义滚动条样式 */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>
